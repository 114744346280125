import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => {
    return (
        <SeoPage
            location={location}
            title="Alcool au volant d’un VTT ou d’une motoneige – Conséquences et défenses avec un avocat!"
            description="“ Accusé d’alcool au volant d’une motoneige ou d’un VTT? Défendez-vous en consultant un avocat en droit criminel spécialisé!"
            fr={undefined}
            lawType="">
            <p>
                <strong>
                    Au Québec, la loi ne s’arrête pas où le sentier hors-piste
                    commence.{' '}
                </strong>
            </p>
            <p>
                Elle s’étend bien au-delà de cette limite, et c’est pourquoi les
                lois concernant l’alcool au volant s’appliquent également aux
                conducteurs de VTT, motoneiges, cotes-à-cotes et autres
                véhicules de plaisance.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Alcool au volant d’un VTT ou d’une motoneige”"
                    alt="Une image contenant extérieur, arbre, neige, ciel"
                />
            </p>
            <p>
                Alors, à quelles conséquences faites-vous face si vous êtes
                accusé d’avoir conduit un véhicule hors route en état d’ébriété?
                Vous risquez d’être accusé au criminel pour alcool au volant.
                Voici donc tout ce que vous devez savoir sur les conséquences et
                défenses possibles pour un tel geste.{' '}
            </p>
            <h2>
                Alcool au volant d’un VTT ou d’une motoneige – TOUT ce qu’il
                faut savoir!{' '}
            </h2>
            <h3>
                Les dispositions du Code de la sécurité routière s’appliquent
                aux VTT/Motoneiges
            </h3>
            <p>
                Depuis maintenant plusieurs années, le Code de la sécurité
                routière s’applique aux motoneigistes et conducteurs de quads.
                C’est donc dire que l’interdiction de conduire en état
                d’ébriété, la suspension du permis et même la révocation du
                permis automobile concernent également les conducteurs
                plaisanciers.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Alcool au volant d’un VTT quatre-roues"
                    alt="Une image contenant moto, extérieur, arbre, terrain"
                />
            </p>
            <h3>
                Les dispositions du Code criminel s’appliquent également aux
                VTT/Motoneiges{' '}
            </h3>
            <p>
                Le fait de conduire une motoneige – même sur un sentier
                hors-piste – alors que le taux d’alcool dépasse le 80mg d’alcool
                par 100ml de sang est un geste criminel. Si vous êtes reconnu
                coupable d’un tel geste, vous serez maintenant titulaire d’un
                casier judiciaire et une amende de 1000$ vous sera imposée.{' '}
            </p>
            <h3>
                En cas de blessure, la SAAQ ne verse pas d’indemnité pour un
                accident de motoneige/VTT
            </h3>
            <p>
                <strong> </strong>
            </p>
            <p>
                La SAAQ verse des indemnités pour les blessures causées sur le
                chemin public par une automobile. Les accidents de motoneige et
                de VTT ne sont donc pas couverts par la SAAQ, même si des droits
                d’immatriculation sont payés. La seule exception concerne les
                accidents impliquant une motoneige et une automobile.{' '}
            </p>
            <h3>
                Causer des blessures en conduisant un VTT ou une motoneige en
                état d’ébriété peut mener à des accusations!{' '}
            </h3>
            <p>
                Le fait de causer des blessures graves ou la mort en conduisant
                un véhicule de plaisance en état d’ébriété pet mener à de
                sérieuses accusations criminelles. Encore une fois, le Code
                criminel s’applique à tout ce qui se passe sur le sentier
                hors-piste.{' '}
            </p>
            <h2>
                Les conséquences de l’alcool au volant d’un VTT ou d’une
                motoneige?{' '}
            </h2>
            <h3>La suspension immédiate du permis de conduire </h3>
            <p>
                Dès l’arrestation pour alcool au volant d’une motoneige, d’un
                VTT ou d’un autre véhicule hors-piste, le permis de conduire
                automobile est suspendu pour 90 jours.{' '}
            </p>
            <h3>L’accusation criminelle pour alcool au volant </h3>
            <p>
                Une accusation criminelle sera portée contre le conducteur de la
                motoneige si le taux d’alcoolémie de ce dernier dépassait le
                0.08.{' '}
            </p>
            <h3>Le casier judiciaire </h3>
            <p>
                Si le conducteur est reconnu coupable d’alcool au volant, il
                deviendra titulaire d’un casier judiciaire et devra acquitter
                une amende de 1000$. La récidive peut même le mener derrière les
                barreaux.{' '}
            </p>
            <h3>L’interdiction de conduire </h3>
            <p>
                Après avoir été déclaré coupable d’alcool au volant, une
                interdiction de conduire de 1 an lui sera imposée. Cela
                l’empêchera de conduire son automobile, ainsi que sa motoneige
                et tout autre véhicule hors-piste.{' '}
            </p>
            <h3>
                Et en cas de récidive, les conséquences sont encore plus graves!{' '}
            </h3>
            <p>
                Une première récidive mène à un emprisonnement de 30 jours,
                alors qu’une seconde amène le conducteur en prison pour 120
                jours. Cela s’applique même si la première infraction a eu lieu
                en automobile ou à bord d’une motoneige.{' '}
            </p>
            <h3>
                L’alcootest est-il obligatoire à bord d’un véhicule de
                plaisance?{' '}
            </h3>
            <p>
                Depuis 2018, les policiers qui patrouillent les sentiers ont le
                droit d’exiger qu’un conducteur de VTT ou de motoneige se
                soumette à un alcootest. Cet alcootest est obligatoire pour le
                conducteur interpellé par les policiers.{' '}
            </p>
            <p>
                Les conducteurs qui refusent de s’y soumettre pourront être
                accusé d’avoir refusé d’obtempérer à un ordre d’un policier, ce
                qui mène à des conséquences encore plus graves que l’alcool au
                volant.{' '}
            </p>
            <h2>
                Accusé d’avoir conduit un VTT ou une motoneige en état
                d’ébriété? Trouvez un avocat!
            </h2>
            <p>
                <strong>
                    Vous avez été intercepté en état d’ébriété sur votre
                    motoneige et vous faites face à des accusations criminelles?{' '}
                </strong>
            </p>
            <p>
                <strong>
                    <Img
                        fluid={data.img2.childImageSharp.fluid}
                        title="Facultés affaiblies en vtt et motoneige"
                        alt="Une image contenant extérieur, motoneige, neige, arbre"
                    />
                </strong>
            </p>
            <p>
                N’attendez pas qu’il soit trop tard pour trouver une solution!
                Remplissez notre formulaire en bas de page pour entrer en
                contact gratuitement avec un avocat spécialisé en accusations
                d’alcool au volant. Notre service ne vous engage d’aucune façon,
                alors qu’attendez-vous pour trouver le bon professionnel pour
                vous défendre?{' '}
            </p>
            <p> </p>
        </SeoPage>
    )
}

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "alcool-volant-motoneige-vtt/motoneige-alcool-volant-consequences.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "alcool-volant-motoneige-vtt/alcool-volant-vtt-quatre-roues.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "alcool-volant-motoneige-vtt/vtt-motoneige-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
